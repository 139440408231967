<template>
  <div>
    <ModalInformes :showModalInformes="showModalInformes" @close="closeModalInformes"
      @handleVisibilidadInformes="handleVisibilidadInformes"></ModalInformes>
    <ModalVisibilidad :showModalVisibilidad="showModalVisibilidad" @close="closeModalVisibilidad"
      @handleVisibilidad="handleVisibilidad"></ModalVisibilidad>
    <MenuAyudaResumen :showModalAyuda="showModalAyuda" @close="closeModalAyuda"></MenuAyudaResumen>
    <ModalMapaFaenas :showModalMapa="showModalMapa" :seriesFaenas="seriesFaenas" @close="closeModal">
    </ModalMapaFaenas>
    <ModalUsuarios :showModal="showModalUsuarios" @close="loadModalUsuarios(false)"></ModalUsuarios>

    <div class="grid xs:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-0">
      <div class="m-0 pt-2 rounded col-span-2">
        <!--- menu nuevo-->
        <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-1 pb-5">
          <div class="container mx-auto pt-1 pb-0 pl-0 pr-0">
            <div class="lg:flex">
              <div class="lg:w-2/3 pl-0">
                <div class="container">
                  <div class="w-full">
                    <table class="mt-1 border-spacing-0 w-full">
                      <tbody class="bg-white">
                        <tr>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                          <td class=""></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="w-full mt-2">
                    <table class="mt-5 border-spacing-0 m-0 w-full pr-3">
                      <tbody class="w-full border-spacing-0">
                        <tr>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-900 text-center"></td>

                          <td class="whitespace-nowrap py-1 text-sm text-gray-900 text-right"></td>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-700 text-left"></td>

                          <td class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"></td>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-700"></td>

                          <td class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"></td>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-700"></td>

                          <td class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"></td>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-700"></td>

                          <td class="whitespace-nowrap py-1 pr-0 text-sm text-gray-900 text-right"></td>
                          <td class="whitespace-nowrap py-1 text-sm text-gray-700"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="lg:w-1/3 pl-1 pr-5 text-center">
                <div class="grid sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 m-0 p-0">


                  <a href="#"
                    class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col" @click="loadModalVisibilidad(true)">
                      <div class="flex-shrink-0">
                        <div
                          class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.034.096-.07.192-.106.287C20.268 16.057 16.478 19 12 19c-4.478 0-8.268-2.943-9.542-7-.036-.095-.072-.191-.106-.287z" />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-xs text-gray-900">Visibilidad</p>
                        </div>
                      </div>
                    </div>
                  </a>

                  <a href="#"
                    class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col" @click="loadModalAyuda(true)">
                      <div class="flex-shrink-0">
                        <div
                          class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-xs text-gray-900">Información</p>
                        </div>
                      </div>
                    </div>
                  </a>

                  <a href="#"
                    class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col" @click="loadModalUsuarios(true)">
                      <div class="flex-shrink-0">
                        <div
                          class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-8 h-8">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-xs text-gray-900" @click="loadModalUsuarios(true)">
                            Usuarios
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="#"
                    class="-m-3 flex flex-col justify-between rounded-lg p-3 transition duration-150 ease-in-out hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col" @click="loadHomeSettings()">
                      <div class="flex-shrink-0">
                        <div
                          class="inline-flex h-10 w-10 items-center justify-center rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                        </div>
                      </div>
                      <div class="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-xs text-gray-900">Configuración</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--- menu nuevo-->

        <div @dragover="handleDragOVer" @dragenter="handleDragEnter" @drop="handleDrop"
          class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 file: bg-gray-200 ml-6">
          <div class="grid grid-cols-1 min-h-full gap-2 bg-white">
            <div v-if="this.$store.getters.getDroppables.length == 0" class="h-64 pt-10">
              <div class="flex items-start mb-4 pb-4">
                <div class="">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-12 h-12">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                  </svg>
                </div>
                <div class="">Arrastre aquí faenas</div>
              </div>
            </div>

            <div class="overflow-x-scroll flex items-start mb-4 pb-4 bg-white">

              <div v-for="f in this.$store.getters.getDroppables" :key="f.id"
                class="bg-white w-100 o shadow rounded-br mb-0 mt-3">
                <div v-if="f.visible == 1" class="ml-auto w-357 mx-2 bg-white" style="width: 357px">

                  <PanelFaena :draggable="true" @drop="handleDropFaena(f.id)" @dragstart="handleDragFaena(f.id)" :class="{
      'border-dotted  border-gray-600 border-2': isDragStart,
      'border-solid border-gray-300 border-2': !isDragStart,
      'border-solid border-red-300 border-2':
        f.id == this.$store.getters.getIdCurrentDroppable,
    }" @dragover="handleDragOver" :f="f" :seriesFaenas="seriesFaenas" @chgHistorialCentros="loadHistorial"
                    @chgFaenaCentros="loadCentros" @sendDivisionesMenuLat="enviaDivisionesMenuLat"></PanelFaena>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 pt-3 pr-3 pl-6">
      <div class="lg:flex">
        <div class="lg:w-1/2 pl-5 text-left">
          <h1 class="text-1xl font-extrabold text-left text-cyan-800">
            Seleccione fechas de consulta
          </h1>
          <CalendarioFaenas @getFecCalendar="getFecCalendar" :fechasSeleccionadas="fechasSeleccionadas">
          </CalendarioFaenas>
        </div>

        <div class="lg:w-1/2 text-left pr-2">

              <div v-if="mostrar == true" >
                <SpinnerLoading></SpinnerLoading>
              </div>

        </div>
      </div>
    </div>

    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 p-3">
      
      <GraficoMultiplesFaenasDiaO2 @loadMapa="loadMapa" :seriesMediciones="seriesOxigeno"
        nombreMedicion="Oxígeno Disuelto">
      </GraficoMultiplesFaenasDiaO2>
    </div>
   
    
    <div class="grid xs:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 p-3">
      <div v-if="mostrar == true" >
                <SpinnerLoading></SpinnerLoading>
              </div>
      <GraficoMultiplesFaenasDiaOrp   @loadMapa="loadMapa" :seriesMediciones="this.seriesOrp" nombreMedicion="ORP"
        tituloY="mV">
      </GraficoMultiplesFaenasDiaOrp>
    </div>

  </div>


</template>
<script>


import CalendarioFaenas from "../calendar/CalendarioFaenas";

import GraficoMultiplesFaenasDiaO2 from "./GraficoMultiplesFaenasDiaO2";
import GraficoMultiplesFaenasDiaOrp from "./GraficoMultiplesFaenasDiaOrp";
import ModalMapaFaenas from "./modal/ModalMapaFaenas";
import MenuAyudaResumen from "./modal/MenuAyudaResumen";
import ModalVisibilidad from "./modal/ModalVisibilidad";
import ModalInformes from "./modal/ModalInformes";
import PanelFaena from "./PanelFaena";

//import AuthHeader  from "@/services/auth.header";
import ModalUsuarios from "./modal/ModalUsuarios";
import axios from "axios";
import SpinnerLoading from "../SpinnerLoading";

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: "FaenasCliente",
  props: ["idEmpresa"],
  emits: [
    "chgFaena",
    "chgFaenaCentros",
    "chgDivisionesMenuLat",
    "pantallasettings",
    "handleDropFaena"
  ],
  components: {
    CalendarioFaenas,

    GraficoMultiplesFaenasDiaO2,
    GraficoMultiplesFaenasDiaOrp,
    ModalMapaFaenas,
    MenuAyudaResumen,
    ModalUsuarios,
    ModalVisibilidad,
    ModalInformes,
    PanelFaena,
    SpinnerLoading 

  },
  mounted() {
    this.getPreferences();
    this.datos_mensuales = [60, 20, 10, 10, 0];
    // this.loadData();
    this.loadDataSesion();
    this.enviaDivisionesMenuLat();
    this.seriesO2Loaded = true;
    this.seriesLoaded = true;


    this.fechasSeleccionadas = this.calculaHoy30Dias()
    this.soloTest=[]
    //this.getFecCalendar(this.fechasSeleccionadas);

    //  
  },
  data() {
    return {
      result_mediciones: false,
      isDragStart: false,
      fechasSeleccionadas: [],
      mostrar: false,
      showModalMapa: false,
      showModalAyuda: false,
      showModalVisibilidad: false,
      showModalInformes: false,
      showModalUsuarios: false,
      seriesLoaded: false,
     
      showPanelAyuda: false,
      datos_mensuales: [],
      faenas: [{}],
      cards: [{}],
      objFaenas: [{}],
      seriesFaenas: [],
      seriesFechas: [],
      contador_instancias: 0,
      faena: "",
      jaula: "",
      fechas: "",
      soloTest: [],
      nomMes: [
        "",
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],


      seriesOrp: [],
      seriesOxigeno: [],

      idO2Disuelto: 5,
      idO2Saturado: 6,
      spinnerO2Disuelto: false,
      spinnerO2Saturado: false
    };
  },
  computed: {
    ...mapState('draggdrop', ['Draggables', 'Droppables'], 'auth', ['userLogin']),
    ...mapGetters('draggdrop', ['getDraggables', 'getDroppables'], 'auth', ['getUserPreferenceItems'])
  },
  methods: {

    ...mapActions("draggdropp", ["droppableAdd", "setIdCurrentDroppable", "setPosCurrentDroppable"], "divisiones", ["getDivisionesApi"]),
    ...mapMutations('draggdrop', ['draggableCreate', 'droppableCreate']),
    loadModalInformes(valor) {
      console.log("modifica estado modal");
      this.showModalInformes = valor;
    },
    loadModalVisibilidad(valor) {
      console.log("modifica estado modal");
      this.showModalVisibilidad = valor;
    },
    loadModalAyuda(valor) {
      console.log("modifica estado modal");
      this.showModalAyuda = valor;
    },
    closeModalAyuda() {
      this.showModalAyuda = false;
    },
    closeModalVisibilidad() {
      this.showModalVisibilidad = false;
    },
    closeModalInformes() {
      this.showModalInformes = false;
    },
    loadAyuda(resp) {
      console.log("ayuda");
      this.showPanelAyuda = resp;
    },
    loadModalUsuarios(valor) {
      this.showModalUsuarios = valor;
    },
    loadDataSesion() {
      this.faenas = this.$store.state.login.userLogin.info.faenas;
    },

    enviaDivisionesMenuLat() {
      this.$emit("chgDivisionesMenuLat", this.faenas);
    },
    loadMapa() {
      console.log("LOAD MAPA");
      this.showModalMapa = true;
    },
    closeModal() {
      this.showModalMapa = false;
    },
    loadDivisiones(f) {
      this.$emit("chgFaena", f);
    },
    loadCentros(f) {
      this.$emit("chgFaenaCentros", f);
    },
    loadHistorial(f) {
      this.$emit("chgHistorialCentros", f);
    },
    loadHomeSettings() {
      this.$emit("chgIdPantalla", { id: 0 });
    },
    async getFecCalendar(f) {
      //  console.log('calendario', f);
      /*  este es el formato de f --> [ "01 10 2023",   "31 10 2023"]   */
      this.soloTest=[]
      if (f[0] == "" || f[1] == "") return false;
   

      const startDate = this.convertirFormatoFecha(f[0]); //2023-11-01
      const endDate = this.convertirFormatoFecha(f[1]);
      this.seriesFechas = this.generaSerieFechas(
        new Date(startDate),
        new Date(endDate)
      );
      this.fechaConsulta = f;

      //this.arrayDias = this.seriesFechas


      this.spinnerO2Disuelto= true;
      this.mostrar = true;
    
      let natural = await this.getSeriesDia(startDate, endDate, this.idO2Disuelto, 'S');

      let oxigenado = await this.getSeriesDia(startDate, endDate,this.idO2Disuelto, 'N');
      this.seriesOxigeno = [[], natural, oxigenado]; // para botones opcion 1, opcion 2
      this.seriesOrp = await this.getSeriesDia(startDate, endDate, 4,'S');


    },





    async getSeriesDia(startDate, endDate, parametroBuscado,es_faena_natural) {
      
  
      
      //this.fechas = `${startDate} / ${endDate}`;
      //console.log("fechas", this.fechas)
      let dataFaena = [];
      try {
      
        const apiKey = this.$store.state.login.tokenLogin;
        const objFaenas = this.$store.state.faenas;
        let arrayFaenas = [];
        arrayFaenas = objFaenas.faenas;

        for (let x = 0; x < arrayFaenas.length; x++) {
          // todas las jaulas de la Faena
          let objFaena = arrayFaenas[x];
          let jaulas = objFaena.unidades.filter(faena=>faena.natural==es_faena_natural); 


          // Array de promesas para las solicitudes a axios
          // Si hay N elementos (jaulas) en el array jaulas, entonces se crearán N promesas en total,
          // una para cada elemento del array.
          const jaulaPromises = jaulas.map((jaula) => {

            const requestData = {
              idUnit: jaula.id,
              tsi: `${startDate} 00:00:00`,
              tsf: `${endDate} 23:59:59`,
              granularity: "DAY",
            };
            return axios
              .post("/api/mon/getUnitData", requestData, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${apiKey}`,
                },
              })
              .then((response) => {

                let sensors = response.data.payload.sensors;


                let arrayLecturas = []
                sensors.forEach(sensor => {
                  sensor.params.forEach(param => {


                    if (param.id == parametroBuscado) {
                      let updatedData = param.values;

                      updatedData.forEach(valor => {


                        let v = valor.value;
                        let obj = {}
                        if (v) {
                          // valor medicion y fecha medicion
                          obj = { 'jaulaId':jaula.id,'value': v, 'ts': valor.ts }
                          arrayLecturas.push({ ...obj });

/* 

                          if (parametroBuscado == 6) {
                            
                              console.log('chk (nuevo)',jaula.id, valor.ts, v);
                              obj = {'jaulaId':jaula.id, 'value': v, 'ts': valor.ts }
                              this.soloTest.push({ ...obj });
                           
                          } */


                        }

                      });
                    }

                  });
                });

                return arrayLecturas

              })
              .catch((error) => {
                console.log("error fetching unit data", error);
                return [];
              });
          });

          const arrayUnidades = await Promise.all(jaulaPromises);
          let dataFaenaDiario = [];
          /*
          1) recorrer arrayUnidades 
              [{
              jaulaId, value, ts
              },
              {
              jaulaId, value, ts
              },
              {
              jaulaId, value, ts
              }]
          2)  agregar a dataFaenaDiario[] objeto :
              { 
                'ts': (aaaa-mm-dd),  // campo de busqueda
                'acumulado': (valor medicion del dia + lo acumulado), 
                'jaulas_con_mediciones_ese_dia':  (cantidad incremental )
              }
          */
          arrayUnidades.forEach((mediciones) => {
            mediciones.forEach((medicion) => {
             
              let n = Math.floor(medicion.value * 100) / 100; // truncar sin rendondear
              const fec = medicion.ts;
              const uni = medicion.jaulaId;
            
              const dateSplit = fec.split(" ")[0];
         
              if (dateSplit) {


                
                let index = dataFaenaDiario.findIndex(function (obj) {     
                  //console.log('chk unidad',uni, obj.ts, dateSplit)
                  return obj.ts == dateSplit;
                });
                

                
                
                if (index !== -1) {
             
                  console.log('chk uni:', uni);
                  console.log('chk (existe fecha ',dateSplit,' en [])')

                  let cantidad_unidades = dataFaenaDiario[index].jaulas_con_mediciones_ese_dia;
                  console.log(uni,': chk (unidades detectadas en ',dateSplit,'):', cantidad_unidades)
                  cantidad_unidades++;

                  let acumulado = parseFloat(dataFaenaDiario[index].acumulado);
                  let  totdia = parseFloat(n)  

                  console.log(uni,': chk (valores acumulado en fecha',dateSplit,':', acumulado)
                  console.log(uni,': chk (agrega en fecha',dateSplit,'):', totdia)

                  //let promedio = (acumulado + totdia) / 2 ;
                  //promedio = Math.floor(promedio * 100) / 100; // truncar sin rendondear

                  //console.log(uni,': chk (promedio',dateSplit,'):', promedio)

                 
                  dataFaenaDiario[index].acumulado = acumulado + totdia;
                  dataFaenaDiario[index].jaulas_con_mediciones_ese_dia = cantidad_unidades;
                
                } else {
             
                  console.log(uni,': chk (agrega *n* en fecha',dateSplit, n)
                  dataFaenaDiario.push({ 'ts': dateSplit, 
                                         'acumulado': n, 
                                         'value': 0, 
                                         'jaulas_con_mediciones_ese_dia': 1 })
                
                }
             


              }

            });
          
         


          });  
          // calcular aqui el promedio
          // recorrer array de objetos y promediar   
          dataFaenaDiario.forEach(function(datodiario) {
                 let promedio =  datodiario.acumulado / datodiario.jaulas_con_mediciones_ese_dia;
                 datodiario.value = Math.floor(promedio * 100) / 100; // truncar sin rendondear
          });
          this.soloTest = dataFaenaDiario;
          let datosdiarios = [];

          if (arrayUnidades) {

            // la idea es generar una serie con todas las fechas seleccionadas
            // en el calendario, e insertar las mediciones de las fechas que
            // Sí registren datos en dataFaenaDiario

            // seriesFechas creado a partir del metodo this.generaSerieFechas
            for (let i = 0; i < this.seriesFechas.length; i++) {

              console.log('seriesFechas', this.seriesFechas[i])
              let result = dataFaenaDiario.find(item => item.ts === this.seriesFechas[i]);
              if (result) {
                let ts = this.convertirFormatoFechaDMA(result.ts);
                console.log('seriesFechas ts', ts);
                let fec = Date.parse(ts);

                datosdiarios.push([fec, parseFloat(result.value)]);
              } else {

                let fec = Date.parse(this.convertirFormatoFechaDMA(this.seriesFechas[i]))

                datosdiarios.push([fec, 0]);
                this.mostrar = true;
              }
            }





          }
          let m = { name: objFaena.nombre, data: datosdiarios };

          dataFaena.push(m);
        } // itera faenas
        this.result_mediciones = true;
        this.mostrar = false;
        return dataFaena;



      } catch (error) {
        console.log("error procesando faenas", error);
      }
    },


    async getPreferences() {
      // iterar FAENAS, si el valor de FAENAS[n].id existe  en PREFERENCIAS[n].id, agregar 
      // en FAENAS[n] el atributo order, con el valor de PREFERENCIAS[n].order

      let objFaenas = this.$store.getters.getFaenas;
      let jsonPref = this.$store.getters.getUserPreferenceItems;
      let objPreferencias = jsonPref;

      const parsedPreferencias = objPreferencias.map(pref => ({
        ...pref,
        id: parseInt(pref.id, 10)
      }));
      objFaenas.forEach(faena => {
        const pref = parsedPreferencias.find(p => p.id === faena.id);
        if (pref) {
          faena.order = pref.order;
          faena.visible = pref.visible;

        }
      });
      objFaenas.sort((a, b) => a.order - b.order);
      await this.$store.dispatch('setFaenas', objFaenas);
      await this.$store.dispatch('droppableCreate', objFaenas);
    },
    manipulaSeriesFechas(min, max) {
      let dataFaena = [];
      /*
       seriesFechas
       *["2023-10-01","2023-10-02" ..."2023-10-31"]
      */
      for (let x = 0; x < this.faenas.length; x++) {
        let data = [];
        for (let i = 0; i < this.seriesFechas.length; i++) {
          //console.log("series fechas:", this.seriesFechas[i]);
          let dma = this.convertirFormatoFechaDMA(this.seriesFechas[i]);
          //console.log(this.seriesFechas[i],"**********")
          //console.log(dma,"**********")
          let f = Date.parse(dma + " 00:00:00 GMT");
          let n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 04:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 08:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 12:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 16:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));
          data.push([f, n]);

          f = Date.parse(dma + " 20:00:00 GMT");
          n = parseFloat(this.getRnd(min, max));

          data.push([f, n]);
        }
        // console.log("manipulados:", data);
        let medicion = { name: this.faenas[x].nombre, data: data };
        dataFaena.push(medicion);
      }
      //console.log("dataFaena", dataFaena);
      return dataFaena;
      //this.chartOptions.series  = dataFaena
    },
    generaSerieFechas(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().substring(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },

    convertirFormatoFecha(fechaOriginal) {
      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {
          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }

      return null;
    },

    convertirFormatoFechaDMA(fechaOriginal) {
      try {
        const partes = fechaOriginal.split("-");

        if (partes.length === 3) {
          const dia = partes[0];
          let paso = parseInt(partes[1])
          const mes = this.nomMes[Number(paso)];
          const año = partes[2];
          const nuevaFecha = `${año} ${mes} ${dia}`;

          console.log('parametro', 'partes', nuevaFecha)
          return nuevaFecha;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },
    convertirFormatoFechaDMA2(fechaOriginal) {
      try {
        const partes = fechaOriginal.split("-");
        if (partes.length === 3) {
          const dia = partes[2];
          const mes = this.nomMes[Number(partes[1])];
          const año = partes[0];
          const nuevaFecha = `${año} ${mes} ${dia}`;
          return nuevaFecha;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },

    getRnd(min, max) {
      let flot = Math.random() * (max - min + 1) + min;

      return flot.toFixed(2);
    },
    showModalHelp() {
      console.log("show modal");
      this.showModal = true;
    },
    handleDragOVer(event) {
      console.log("over");
      event.preventDefault();
    },
    handleDragEnter(event) {
      console.log("enter", event);
    },
    handleDrop() {
      console.log("DROP");
      this.$store.dispatch("droppableAdd");
      this.isDragStart = false;
    },
    handleDropFaena(id) {
      console.log("DROP en Faena", id);
      this.$store.dispatch("setPosCurrentDroppable", id);
      this.$emit("handleDropFaena");
    },
    handleVisibilidad() {
      this.$emit("handleDropFaena");
    },
    handleDragFaena(id) {
      console.log("tomando faena ", id);
      this.$store.dispatch("setIdCurrentDroppable", id);
      this.isDragStart = true;
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    calculaHoy30Dias() {

      let hoy = new Date();
      let diaHoy = String(hoy.getDate()).padStart(2, '0');
      let mesHoy = String(hoy.getMonth() + 1).padStart(2, '0');
      let añoHoy = hoy.getFullYear();
      let fechaHoy = `${diaHoy} ${mesHoy} ${añoHoy}`;



      let hace30Dias = new Date();
      hace30Dias.setDate(hoy.getDate() - 30);
      let diaHace30 = String(hace30Dias.getDate()).padStart(2, '0');
      let mesHace30 = String(hace30Dias.getMonth() + 1).padStart(2, '0');
      let añoHace30 = hace30Dias.getFullYear();
      let fechaHace30Dias = `${diaHace30} ${mesHace30} ${añoHace30}`;

      return [fechaHace30Dias, fechaHoy]

    }
  },

};
</script>
